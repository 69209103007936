<template>
  <div class="contentConfigFraisResponsableFilialeParams">
    <div class="entete">
      <div class="box-setting-header ">
        <div class="title">Gestion des variables responsable filiale</div>
        <div>
          <b-button
            variant="success"
            class="config-btn ml-2"
            v-b-modal.modal-add-frais-responsable-params
            ><font-awesome-icon icon="plus" /> Nouvelle configuration</b-button
          >
          <b-modal
            id="modal-add-frais-responsable-params"
            ref="modal-add-frais-responsable-params"
            title="Nouvelle configuration"
            no-close-on-backdrop
            :hide-footer="true"
            :hide-header="true"
            @hidden="resetModal"
            modal-class="cutsom-modal-bootstrap ModalFriasResponsableFiliale"
          >
            <div class="hader mb-2">
              <div class="titleSetting">Nouvelle configuration</div>
              <div
                class="iconClose"
                @click.prevent="hideModal('modal-add-frais-responsable-params')"
              >
                <font-awesome-icon icon="times" />
              </div>
            </div>
            <Card>
              <template v-slot:body>
                <form
                  @submit.prevent="
                    handleSubmitConfigParamsFraisResponsableFiliale
                  "
                  class="form-modal-custom-style"
                >
                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="Année"
                      label-for="year"
                      class=" input-modal-champ "
                    >
                      <b-form-select
                        id="year"
                        v-model="fraisResponsableFilialeToConfig.year"
                        :options="ListYear"
                        required
                        @change="handleChangeYearModel"
                        class="b-form-select-raduis "
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Mois"
                      label-for="month"
                      class=" input-modal-champ "
                    >
                      <b-form-select
                        id="month"
                        v-model="fraisResponsableFilialeToConfig.month"
                        :options="computedGetMonthForYearTh"
                        required
                        text-field="text"
                        value-field="value"
                        class="b-form-select-raduis "
                        @change="handleChangeMonthModal"
                      ></b-form-select>
                    </b-form-group>
                  </div>

                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="filiale"
                      label-for="filiale"
                      class=" input-modal-champ "
                    >
                      <b-form-select
                        id="filiale"
                        v-model="fraisResponsableFilialeToConfig.filiale"
                        :options="computedGetFilialeFiliale"
                        required
                        text-field="text"
                        value-field="value"
                        class="b-form-select-raduis "
                        @change="handleChangeMonthModal"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label="Responsable"
                      label-for="responsable"
                      class=" input-modal-champ "
                    >
                      <b-form-select
                        id="responsable"
                        v-model="fraisResponsableFilialeToConfig.responsable"
                        :options="computedGetFilialeResponsable"
                        required
                        text-field="text"
                        value-field="value"
                        class="b-form-select-raduis "
                        @change="handleChangeMonthModal"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="Pourcentage"
                      label-for="percent"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        id="percent"
                        v-model="fraisResponsableFilialeToConfig.percent"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label="Holding Name"
                      label-for="name_holding"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        id="name_holding"
                        v-model="fraisResponsableFilialeToConfig.name_holding"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="actionModel">
                    <div class="messageError">
                      <div v-if="error" class="error">
                        <ul v-if="Array.isArray(error)" class="mb-0">
                          <li v-for="(e, index) in error" :key="index">
                            {{ e }}
                          </li>
                        </ul>
                        <div v-else>{{ error }}</div>
                      </div>
                    </div>
                    <b-button type="submit" class="button-valide-style ">
                      <span>
                        Valider
                        <div v-if="loading" class="loading ml-2">
                          <div class="spinner-border" role="status"></div>
                        </div>
                      </span>
                    </b-button>
                  </div>
                </form>
              </template>
            </Card>
          </b-modal>
        </div>
      </div>
      <div class="ligne-box-setting-header mt-1"></div>
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style"
            v-model="filterYear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Mois</span>
          </div>

          <b-form-select
            class="b-form-select-new-style"
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeMonth"
          ></b-form-select>
        </div>
        <div class="error-message ml-3 ">
          <div v-if="getErrorFraisResponsableFilialeParamsTh" class="error">
            <ul
              v-if="Array.isArray(getErrorFraisResponsableFilialeParamsTh)"
              class="mb-0"
            >
              <li
                v-for="(e, index) in getErrorFraisResponsableFilialeParamsTh"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorFraisResponsableFilialeParamsTh }}</div>
          </div>
        </div>
        <div
          v-if="getLoagingFraisResponsableFilialeParamsTh"
          class="chargement ml-1"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-tarif-filiale"
          :items="getFraisResponsableFilialeParamsThData"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          empty-text="Il n'y a aucun enregistrement à afficher"
          tbody-tr-class="ligneNormale"
        >
          <template v-slot:cell(Actions)="data">
            <b-button
              class="button-succes-style mt-2 mb-2"
              size="sm"
              variant="success"
              @click.prevent.stop="handleUpdate(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
          </template>
          <template v-slot:cell(year)="data">
            {{ data.item.year }}
          </template>
          <template v-slot:cell(month)="data">
            {{ data.item.month }}
          </template>
          <template v-slot:cell(filiale)="data">
            <span>{{ data.item.filiale.name }}</span>
          </template>

          <template v-slot:cell(responsable)="data">
            <span>{{ data.item.responsable.name }}</span>
          </template>
          <template v-slot:cell(percent)="data">
            <span>{{ data.item.percent }}</span>
          </template>
          <template v-slot:cell(name_holding)="data">
            <span>{{ data.item.name_holding }}</span>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowFraisResponsableFilialeParamsTh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>

      <b-modal
        id="modal-update-frais-Responsable-params"
        ref="modal-update-frais-Responsable-params"
        title="Configuration"
        no-close-on-backdrop
        :hide-footer="true"
        :hide-header="true"
        @hidden="resetModal"
        modal-class="cutsom-modal-bootstrap ModalFriasResponsableFiliale"
      >
        <div class="hader mb-3">
          <div class="titleSetting">Configuration</div>
          <div
            class="iconClose"
            @click.prevent="hideModal('modal-update-frais-Responsable-params')"
          >
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <Card>
          <template v-slot:body>
            <form
              @submit.prevent="handleSubmitUpdateFraisResponsable"
              v-if="fraisResponsableToUpdate"
              class="form-modal-custom-style"
            >
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Année"
                  label-for="year"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="year"
                    v-model="fraisResponsableToUpdate.year"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Mois"
                  label-for="month"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="month"
                    v-model="fraisResponsableToUpdate.month"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="filiale"
                  label-for="filiale"
                  class=" input-modal-champ "
                >
                  <b-form-input
                    id="filiale"
                    v-model="fraisResponsableToUpdate.filiale.name"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Responsable"
                  label-for="responsable"
                  class=" input-modal-champ "
                >
                  <b-form-input
                    id="responsable"
                    v-model="fraisResponsableToUpdate.responsable.name"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Pourcentage"
                  label-for="percent"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="percent"
                    v-model="fraisResponsableToUpdate.percent"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Holding Name"
                  label-for="name_holding"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="name_holding"
                    v-model="fraisResponsableToUpdate.name_holding"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="actionModel">
                <div class="messageError">
                  <div v-if="error" class="error">
                    <ul v-if="Array.isArray(error)" class="mb-0">
                      <li v-for="(e, index) in error" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ error }}</div>
                  </div>
                </div>
                <b-button type="submit" class="button-valide-style ">
                  <span>
                    Valider
                    <div v-if="loading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </form>
          </template>
        </Card>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Gestion-des-variables-responsable-filiale',
  data() {
    return {
      fraisResponsableFilialeToConfig: {
        year: null,
        month: null,
        filiale: null,
        responsable: null,
        name_holding: null,
        percent: null
      },
      filterYear: null,
      filterMonth: null,
      ListYear: [],
      error: null,
      loading: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      fields: [
        { key: 'year', label: 'Année' },
        { key: 'month', label: 'Mois' },

        { key: 'filiale', label: 'Filiale' },
        { key: 'responsable', label: 'Responsable' },
        { key: 'percent', label: 'Pourcentage' },
        { key: 'name_holding', label: 'Holding Name' },

        {
          key: 'Actions',
          label: 'Actions',
          thClass: 'thvalidation',
          tdClass: 'tdvalidation'
        }
      ],
      fraisResponsableToUpdate: null
    };
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'newConfigFraisResponsableFilialeParamsTh',
      'fetchAllFraisResponsableFilialeParamsTh',
      'updateConfigParamsFraisResponsableFilialeParamsTh',
      'getSettingFilialeTh'
    ]),
    async handleSubmitConfigParamsFraisResponsableFiliale() {
      this.loading = true;
      this.fraisResponsableFilialeToConfig.month_number = this.getMonthForYearTh.filter(
        item => item.month_name == this.fraisResponsableFilialeToConfig.month
      )[0].month;
      const response = await this.newConfigFraisResponsableFilialeParamsTh(
        this.fraisResponsableFilialeToConfig
      );
      if (response.succes) {
        this.hideModal('modal-add-frais-responsable-params');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.error = null;
      this.loading = false;
      this.fraisResponsableFilialeToConfig = {
        year: null,
        month: null,
        filiale: null,
        responsable: null,
        name_holding: null,
        percent: null
      };
      this.fraisResponsableToUpdate = null;
      this.fetchMonthForYearTH(this.filterYear);
    },
    handleChangeYearModel() {
      this.fraisResponsableFilialeToConfig.month = null;
      this.fetchMonthForYearTH(this.fraisResponsableFilialeToConfig.year);
    },
    handleChangeMonthModal() {
      this.fraisResponsableFilialeToConfig.week = null;
    },
    handleChange(payload) {
      if (payload && payload.champName && this[payload.champName]) {
        this[payload.champName] = payload.value;
      }
      this.filterTable();
    },
    handleChangeMonth() {
      this.filterTable();
      this.setLocalStorageParamsFraisResponsableFiliale();
    },

    handleChangeYear() {
      this.filterMonth = null;
      this.filterTable();
      this.setLocalStorageParamsFraisResponsableFiliale();
    },
    changePerPage() {
      this.page = 1;
      this.filterTable();
      this.setLocalStorageParamsFraisResponsableFiliale();
    },
    pagination(paginate) {
      this.page = paginate;
      this.filterTable();
      this.setLocalStorageParamsFraisResponsableFiliale();
    },
    filterTable() {
      this.fetchAllFraisResponsableFilialeParamsTh({
        year: this.filterYear,
        month: this.filterMonth,
        per_page: this.perPage,
        page: this.page
      });
      this.setLocalStorageParamsFraisResponsableFiliale();
    },
    handleUpdate(item) {
      this.fraisResponsableToUpdate = { ...item };
      this.$refs['modal-update-frais-Responsable-params'].show();
    },
    async handleSubmitUpdateFraisResponsable() {
      this.loading = true;
      const response = await this.updateConfigParamsFraisResponsableFilialeParamsTh(
        this.fraisResponsableToUpdate
      );
      if (response.succes) {
        this.hideModal('modal-update-frais-Responsable-params');
      } else {
        this.error = response.eror;
        this.loading = false;
      }
    },
    setLocalStorageParamsFraisResponsableFiliale() {
      localStorage.setItem(
        'gestion-params-frais-responsable-filiale-th',
        JSON.stringify({
          year: this.filterYear,
          month: this.filterMonth
        })
      );
    }
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getLoagingFraisResponsableFilialeParamsTh',
      'getErrorFraisResponsableFilialeParamsTh',
      'getTotalRowFraisResponsableFilialeParamsTh',
      'getFraisResponsableFilialeParamsThData',
      'getSettingFilialesTh'
    ]),
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedGetFilialeResponsable() {
      let tab = [];

      if (
        this.fraisResponsableFilialeToConfig &&
        this.fraisResponsableFilialeToConfig.filiale != null
      ) {
        let table = this.getSettingFilialesTh.filter(
          item => item.id == this.fraisResponsableFilialeToConfig.filiale
        );

        tab = table[0].responsables.map(item => ({
          value: item.id,
          text: item.name
        }));
      }

      return tab;
    },
    computedGetFilialeFiliale() {
      let table = this.getSettingFilialesTh.filter(
        item => item.type == 'professionnel'
      );

      const tab = table.map(item => ({
        value: item.id,
        text: item.name
      }));

      return tab;
    }
  },
  components: {
    Card: () => import('../../component/card.vue')
  },
  async mounted() {
    var courantYear = moment().year();
    this.filterYear = courantYear;
    if (localStorage.getItem('gestion-params-frais-responsable-filiale-th')) {
      this.filterYear = JSON.parse(
        localStorage.getItem('gestion-params-frais-responsable-filiale-th')
      ).year;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;
    if (localStorage.getItem('gestion-params-frais-responsable-filiale-th')) {
      this.filterMonth = JSON.parse(
        localStorage.getItem('gestion-params-frais-responsable-filiale-th')
      ).month;
    } else {
      this.year = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageParamsFraisResponsableFiliale();
    }
    if (
      sessionStorage.getItem('page-gestion-params-frais-responsable-filiale-th')
    ) {
      this.page = sessionStorage.getItem(
        'page-gestion-params-frais-responsable-filiale-th'
      );
    } else {
      sessionStorage.setItem(
        'page-gestion-params-frais-responsable-filiale-th',
        this.page
      );
    }

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }

    this.filterTable();
    this.getSettingFilialeTh();
  }
};
</script>

<style scoped lang="scss">
.contentConfigFraisResponsableFilialeParams {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .body-box-setting {
    height: calc(100vh - 194px) !important;
  }
  .table-tarif-filiale {
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
    margin-bottom: 0px;
  }
  .chargement {
    font-size: 8px;
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
<style lang="scss">
.ModalFriasResponsableFiliale {
  .modal-dialog {
    max-width: 70%;
  }
}
</style>
